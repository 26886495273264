import React from "react";

const NotFound = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
    <div className="loading" aria-label="Loading">
  <div className="bg-wrap" />
  <div className="pizza" aria-hidden="true">
    <div className="pizza_piece">
      <div className="pizza_crust pizza_inner" />
      <div className="pizza_sause pizza_inner" />
      <div className="pizza_cheeze pizza_inner" />
      <div className="pizza_filling"></div>
    </div>
    <div className="pizza_piece">
      <div className="pizza_crust pizza_inner" />
      <div className="pizza_sause pizza_inner" />
      <div className="pizza_cheeze pizza_inner" />
      <div className="pizza_filling" />
    </div>
    <div className="pizza_piece">
      <div className="pizza_crust pizza_inner" />
      <div className="pizza_sause pizza_inner" />
      <div className="pizza_cheeze pizza_inner" />
      <div className="pizza_filling" />
    </div>
    <div className="pizza_piece">
      <div className="pizza_crust pizza_inner" />
      <div className="pizza_sause pizza_inner" />
      <div className="pizza_cheeze pizza_inner" />
      <div className="pizza_filling" />
    </div>
  </div>
</div>

    </>
  );
};
export default NotFound;

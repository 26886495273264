import React, { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import $ from "jquery"; // You may want to consider using a React-specific library for DOM manipulation instead of jQuery
import PahoMQTT from "paho-mqtt";
import Swal from "sweetalert2";
// import { Client } from "mqtt";
mapboxgl.accessToken =
  "pk.eyJ1IjoicGl6emFicm9zIiwiYSI6ImNsbW91ZjFueDExcjEycXFhNTBpcm14bmUifQ.pVcxXsZ0s46IoDwmQ9UMvw";
function OrderTracker() {
  const [data, setData] = React.useState({});
  const [id, setId] = React.useState("");
  const [client1, setClient1] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [cuslat, setcuslat] = React.useState(30.964386);
  const [cuslng, setcuslng] = React.useState(76.5149382);
  const [status, setStatus] = React.useState("");
  // const [initialized, setInitialized] = React.useState(false);
  const [sdata, setSdata] = React.useState([
    "Order Received",
    "Preparing",
    "In Oven",
    "Packing",
    "Pickup Ready",
    "Out For Delivery",
  ]);
  const [orderId, setOrderId] = useState(null);
  const [isValidId, setIsValidId] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { trackingid } = useParams();

  useEffect(() => {
    init();

    if (trackingid) {
      setId(trackingid);
      callApi(trackingid);
    }
  }, [trackingid]);

 
  let client = null;

  const callApi = (id) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://api.kiwieats.co.nz/app/getdetailsbytrackingid?trackingid=${trackingid}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData(result.data);
          console.log("data", result);

          setcuslat(result.data.drop_lat);
          setcuslng(result.data.drop_lng);
          setStatus(result.data.order_status);
          setOrderId(result?.data?.id); // Set orderId state here
          setIsLoading(false)

          let items = JSON.parse(result.data.orderItems.order_items);
          setItems(items);
          console.log("aa", items);
        } else {
          setIsValidId(false);
      setIsLoading(false)

        }
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire("An error occurred while fetching the order details");
      setIsLoading(false)
      });

  };

  function init() {
    client = getClient();
    client.onMessageArrived = processMessage;
    client.onConnectionLost = function (e) {
      console.log(e);
    };
  }

  function getClient(success) {
    console.log("getclient");
    if (!success) success = () => console.log("connected");
    const _client = initClient();
    const connectOptions = {
      useSSL: true,
      keepAliveInterval: 60,
      timeout: 3,
      mqttVersion: 4,
      onSuccess: onConnect,
      onFailure: onError,
      userName: "Kiwi",
      password: "We-exJ$tx.OcHTn{LKbI",
    };

    _client.connect(connectOptions);
    console.log("connecting");
    $("#connection-status").html("Connecting..");
    return _client;
  }

  function onError(err) {
    $("#connection-status").html(err);
    console.log(err);
  }

  function onConnect() {
    console.log("onConnect");
    $("#connection-status").html("Connected!");
    $("#test-console").show();

    setId(trackingid);
    // callApi(param[1]);

    client.subscribe(`ordertracker/${trackingid}/status`);
  }
  // gets MQTT client
  function initClient() {
    const clientId = Math.random().toString(36).substring(7);
    console.log(getEndpoint());
    const _client = new PahoMQTT.Client(getEndpoint(), clientId);
    console.log("initclient");
    setClient1(_client);
    // publish method added to simplify messaging
    _client.publish = function (topic, payload) {
      let payloadText = JSON.stringify(payload);
      let message = new PahoMQTT.Message(payloadText);
      message.destinationName = topic;
      message.qos = 0;
      _client.send(message);
    };
    //_client.subscribe("sc/orders/");
    return _client;
  }
  const [notificationPermission, setNotificationPermission] = useState(
    Notification.permission
  );
  useEffect(() => {
    setNotificationPermission(Notification.permission);
  }, []);
  const handleGetNotifyClick = () => {
    // Check if the Notification API is supported by the browser
    if (!("Notification" in window)) {
      alert("This browser does not support notifications.");
      return;
    }

    // Check the current notification permission
    if (Notification.permission === "granted") {
      alert("You have already granted permission for notifications.");
    } else if (Notification.permission === "denied") {
      alert("You have denied permission for notifications.");
    } else {
      // Request permission from the user
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          alert(
            "Thank you for enabling notifications! You'll now receive updates on your order status."
          );
          window.location.reload();
        } else {
          alert("You denied the permission for notifications.");
        }
      });
    }
  };
  async function fetchRestaurantName() {
    const response = await fetch(
      `https://api.kiwieats.co.nz/app/getdetailsbytrackingid?trackingid=${trackingid}`
    );
    const result = await response.json();
    if (result.status === true) {
      setIsLoading(false)
      return result.data?.restaurant?.company_name;
    } else {
      throw new Error("Invalid Tracking Id");
      setIsLoading(false)

    }
  }
  async function processMessage(message) {
    console.log("process message");
    let s = JSON.parse(message.payloadString).status;
    console.log("Notification");
    const restaurantName = await fetchRestaurantName();
    console.log("restaurantname", restaurantName);

    const handleSendNotification = () => {
      if (notificationPermission === "granted") {
        const title = ` ${s}`;
        const options = {
          body: `${restaurantName}`,
          icon: "https://bucket-dev-sss.s3.amazonaws.com/toolbox/users-images/5afd3031-5cb3-447a-8c78-14dd5d59b16e-default_image.jpg",
        };
        new Notification(title, options);
      } else {
        // alert('Please grant permission to send notifications.');
      }
    };
    handleSendNotification();
    console.log(s);
    setStatus(s);
    if (trackingid) {
      let string = `ordertracker/${trackingid}/location`;
      let string1 = `ordertracker/${trackingid}/status`;
      if (
        message?.destinationName === string ||
        message?.destinationName === string1
      ) {
        // addText(message.payloadString);
      }
    }
  }

  function publishMessage() {
    client1.subscribe(`ordertracker/${trackingid}/status`);
    sendMessage();
    // sendMessage1();
  }
  function sendMessage() {
    try {
      const topic = `ordertracker/${trackingid}/location`;
      const payload = {
        lat: 30.6586325,
        lng: 76.783189,
        cuslat: cuslat,
        cuslng: cuslng,
      };

      const payloadText = JSON.stringify(payload);

      const message = new PahoMQTT.Message(payloadText);
      message.destinationName = topic;
      message.qos = 0;

      client1.send(message);

      console.log("Published:", message);
    } catch (error) {
      console.log(error);
    }
  }
  // function sendMessage1() {
  //   try {
  //     const topic = `ordertracker/${id}/status`;
  //     const payload = {
  //       status: "Out For Delivery",
  //     };

  //     const payloadText = JSON.stringify(payload);

  //     const message = new PahoMQTT.Message(payloadText);
  //     message.destinationName = topic;
  //     message.qos = 0;

  //     client1.send(message);

  //     console.log("Published:", message);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // function addText(message) {
  //   console.log(message);

  //   let json = JSON.parse(message);
  //   if (json?.status) {
  //     if (json?.status === "Out For Delivery") {
  //       setStatus(json?.status);
  //       const map = new mapboxgl.Map({
  //         container: "map",
  //         style: "mapbox://styles/mapbox/streets-v12",
  //         center: [cuslng, cuslat],
  //         zoom: 8,
  //       });

  //       // Create a default Marker and add it to the map.
  //       const marker1 = new mapboxgl.Marker()
  //         .setLngLat([cuslng, cuslat])
  //         .addTo(map);

  //       const marker2 = new mapboxgl.Marker({ color: "black", rotation: 45 })
  //         .setLngLat([lng, lat])
  //         .addTo(map);

  //       const directions = new MapboxDirections({
  //         accessToken: mapboxgl.accessToken,
  //       });

  //       map.addControl(directions, "top-left");
  //     }
  //   }
  //   if (json?.lat) {
  //     setlat(json?.lat);
  //     setlng(json?.lng);
  //     setcuslat(json?.cuslat);
  //     setcuslng(json?.cuslng);

  //     const map = new mapboxgl.Map({
  //       container: "map",
  //       style: "mapbox://styles/mapbox/streets-v12",
  //       center: [parseFloat(json.cuslng), parseFloat(json.cuslat)],
  //       zoom: 8,
  //     });

  //     // Create a default Marker and add it to the map.
  //     const marker1 = new mapboxgl.Marker()
  //       .setLngLat([parseFloat(json.cuslng), parseFloat(json.cuslat)])
  //       .addTo(map);

  //     // Create a default Marker, colored black, rotated 45 degrees.
  //     const marker2 = new mapboxgl.Marker({ color: "black", rotation: 45 })
  //       .setLngLat([json.lng, json.lat])
  //       .addTo(map);
  //   }

  //   message = currentTime() + ": " + message;
  //   var logsarea = $("#txt-logs");
  //   if (logsarea.val()) {
  //     logsarea.val(logsarea.val() + "\n" + message);
  //   } else {
  //     logsarea.val(message);
  //   }
  // }

  // function currentTime() {
  //   var today = new Date();
  //   var h = today.getHours();
  //   var m = today.getMinutes();
  //   var s = today.getSeconds();
  //   // add a zero in front of numbers<10
  //   m = checkTime(m);
  //   s = checkTime(s);
  //   return h + ":" + m + ":" + s;
  // }

  // function checkTime(i) {
  //   if (i < 10) {
  //     i = "0" + i;
  //   }
  //   return i;
  // }

  $(document).ready(function () {
    $("#btn-message").keyup(function (event) {
      if (event.keyCode === 13) {
        publishMessage();
      }
    });
  });

  function p4() {}
  p4.sign = function (key, msg) {
    const hash = CryptoJS.HmacSHA256(msg, key);
    return hash.toString(CryptoJS.enc.Hex);
  };
  p4.sha256 = function (msg) {
    const hash = CryptoJS.SHA256(msg);
    return hash.toString(CryptoJS.enc.Hex);
  };
  p4.getSignatureKey = function (key, dateStamp, regionName, serviceName) {
    const kDate = CryptoJS.HmacSHA256(dateStamp, "AWS4" + key);
    const kRegion = CryptoJS.HmacSHA256(regionName, kDate);
    const kService = CryptoJS.HmacSHA256(serviceName, kRegion);
    const kSigning = CryptoJS.HmacSHA256("aws4_request", kService);
    return kSigning;
  };

  function getEndpoint() {
    const REGION = "ap-southeast-2";

    const IOT_ENDPOINT = "a24szmh3k4rsdt-ats.iot.ap-southeast-2.amazonaws.com";

    let qs = "x-amz-customauthorizer-name=IoT-Authorizer2";

    return `wss://${IOT_ENDPOINT}/mqtt?${qs}`;
  }
  const ItemList = ({ items }) => {
    console.log(items);
    // Use the map() function to create an array of React components
    const itemComponents = items.map((item, index) => (
      <tr key={index}>
        <td>{item.item}</td>
        <td>{item.quantity}</td>
        <td>${item.price}</td>
      </tr>
    ));
 
    return <>{itemComponents}</>;
  };
  return (
    <>
    {isLoading ? ( // Display loader if data is still loading
      <div className="loading" aria-label="Loading">
        <div className="bg-wrap" />
        <div className="pizza" aria-hidden="true">
          <div className="pizza_piece">
            <div className="pizza_crust pizza_inner" />
            <div className="pizza_sause pizza_inner" />
            <div className="pizza_cheeze pizza_inner" />
            <div className="pizza_filling" />
          </div>
          <div className="pizza_piece">
            <div className="pizza_crust pizza_inner" />
            <div className="pizza_sause pizza_inner" />
            <div className="pizza_cheeze pizza_inner" />
            <div className="pizza_filling" />
          </div>
          <div className="pizza_piece">
            <div className="pizza_crust pizza_inner" />
            <div className="pizza_sause pizza_inner" />
            <div className="pizza_cheeze pizza_inner" />
            <div className="pizza_filling" />
          </div>
          <div className="pizza_piece">
            <div className="pizza_crust pizza_inner" />
            <div className="pizza_sause pizza_inner" />
            <div className="pizza_cheeze pizza_inner" />
            <div className="pizza_filling" />
          </div>
        </div>
      </div>
    ) : isValidId ? (
      <div className="container">
        <div className="card">
          <div className="header-div">
            <h2>Order Tracker</h2>
            {notificationPermission !== "granted" && (
              <button
                className="enable-notifications-btn"
                onClick={handleGetNotifyClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-bell"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                </svg>
                Get Notified
              </button>
            )}
          </div>
          <div className="status-container">
            {sdata.map((n) => (
              <>
                {n === status ? (
                  <div className="status-item packing">
                    {n === "Pickup Ready" || n === "Out For Delivery" ? (
                      <div className="status-dot" />
                    ) : (
                      status === n && <div className="status-checkmark">✓</div> || <div className="status-checkmark"></div>
                    )}
                    <div className="current-text">{n}</div>
                  </div>
                ) : (
                  <div key={n} className="status-item">
                  {sdata.indexOf(n) <= sdata.indexOf(status) ? (
                    <div className="status-checkmark">✓</div>
                  ) : (
                    <div className="status-checkmark"></div>
                  )}
                  <div className="status-text">{n}</div>
                </div>
                )}
              </>
            ))}
          </div>
        </div>
  
        <div className="card">
          <h2>Order #{data?.order_no}</h2>
          <p>
            <strong>Customer:</strong> {data?.customer_name}
          </p>
          <p>
            <strong>Address:</strong> {data?.drop_location}
          </p>
          <div className="table-container">
            <table>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Amount</th>
              </tr>
  
              <ItemList items={items} />
  
              <tr className="total-row">
                <td colSpan="2">Total Items</td>
                <td>${data?.order_amount}</td>
              </tr>
              <tr className="total-row">
                <td colSpan="2">Delivery Charge</td>
                <td>${data?.delivery_charges}</td>
              </tr>
              <tr className="total-row">
                <td colSpan="2">Total</td>
                <td>
                  $
                  {(Number(data?.delivery_charges || 0) +
                    Number(data?.order_amount || 0)).toFixed(2)}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    ) : (
      <main className="error-container">
        <div className="error-text-container">
          <h1 className="error-mainfourzerofour">404</h1>
          <span>Oops... There's something wrong.</span>
        </div>
        <div className="error-pizza" />
        <div className="error-cheese">
          <div className="error-pepperoni error-pep-1" />
          <div className="error-pepperoni error-pep-2" />
          <div className="error-pepperoni error-pep-3" />
          <div className="error-pepperoni error-pep-4" />
          <div className="error-pepperoni error-pep-5" />
          <div className="error-pepperoni error-pep-6" />
          <div className="error-pepperoni error-pep-7" />
          <div className="error-pepperoni error-pep-8" />
          <div className="error-pepperoni error-pep-9" />
          <div className="error-pepperoni error-pep-10" />
          <div className="error-pepperoni error-pep-11" />
          <div className="error-line error-line-1" />
          <div className="error-line error-line-2" />
          <div className="error-line error-line-3" />
          <div className="error-line error-line-4" />
        </div>
      </main>
    )}
  </>
  
  
  );
}

export default OrderTracker;

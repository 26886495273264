import { BrowserRouter, Routes, Route } from "react-router-dom";
 
import NotFound from "./NotFound";
import OrderTracker from "./Iot";
import './App.css'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:trackingid" element={<OrderTracker />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
